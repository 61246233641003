import { UIEntity } from 'src/app/shared/models/entity.model';

import { propsFactory } from '@ngneat/elf';

export enum StoreName {
  Auth = 'auth',
  Site = 'site',
  Run = 'run',
  Scenario = 'scenario',
  OndLibrary = 'ondLibrary',
  PanLibrary = 'panLibrary',
  WtgLibrary = 'wtgLibrary',
  Support = 'support',
}

export const {
  withLoading,
  updateLoading,
  selectLoading,
  resetLoading,
  getLoading,
  setLoading,
  setLoadingInitialValue,
} = propsFactory<
  UIEntity['loading'],
  'loading',
  { loading: UIEntity['loading'] }
>('loading', { initialValue: false });

export const {
  withLoaded,
  updateLoaded,
  selectLoaded,
  resetLoaded,
  getLoaded,
  setLoaded,
  setLoadedInitialValue,
} = propsFactory<UIEntity['loaded'], 'loaded', { loaded: UIEntity['loaded'] }>(
  'loaded',
  { initialValue: false },
);

export const {
  withError,
  updateError,
  selectError,
  resetError,
  getError,
  setError,
  setErrorInitialValue,
} = propsFactory<UIEntity['error'], 'error', { error: UIEntity['error'] }>(
  'error',
  { initialValue: undefined },
);

import { InjectionToken } from '@angular/core';

import { environment } from './environment';
import { Environment } from './environment.model';

export const ENV_CONFIG = new InjectionToken<Environment>('env-config', {
  factory: () => environment,
  providedIn: 'root',
});

export const OKTA_SCOPES = [
  'email',
  'profile',
  'openid',
  'offline_access',
  'hyppo',
];

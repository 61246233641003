import { OKTA_SCOPES } from './environment.config';
import { Environment } from './environment.model';

export const environment: Environment = {
  production: false,
  auth: {
    issuer: 'https://et-tractebel.oktapreview.com/oauth2/default',
    clientId: '0oa80vjcwlrQmLqDr0x7',
    redirectUri: window.location.origin + '/login/callback',
    postLogoutRedirectUri: window.location.origin + '/',
    scopes: OKTA_SCOPES,
    storageManager: {
      token: {
        storageType: 'cookie',
        sameSite: 'strict',
        secure: true,
        sessionCookie: true,
      },
    },
  },
  api: {
    url: 'https://dev.hyppo.te-ded.com/api',
  },
  websocket: {
    url: 'wss://dev.hyppo.te-ded.com',
  },
  sentry: {
    dsn: 'https://e3a1f5e415b6e531edeb1faf7bf4d95c@o258615.ingest.sentry.io/4506340964368384',
  },
  trainingLink:
    'https://engie.sharepoint.com/:u:/r/sites/TETDataViz/Shared Documents/HyppoTraining/Dev/index.aspx',
};
